<template>
  <div>
    <span class="primary--text mb-3">Términos y condiciones</span>
    <div class="mt-3">
      <app-rich-text-component />
    </div>
    <v-row class="mt-5">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn width="30%" class="mr-5" color="secondary" outlined
          >Revertir Cambios</v-btn
        >
        <v-btn width="30%" color="secondary">Guardar</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "TerminosCondicionesComponent",
};
</script>
