<template>
  <Fragment>
    <v-card>
      <v-card-actions>
        <v-btn @click="modal=true" color="secondary">Agregar proceso padre</v-btn>
      </v-card-actions>
      <v-card-text>
        <data-table-component :headers="headers" :items="items">
          <template v-slot:item.acciones="{ item }">
            <v-btn class="secondary white--text" @click="(idProceso = item?.id),(modalAgregarProceso=true) " text>
              Modificar procesos adscritos
            </v-btn>
          </template>
          <template v-slot:item.created_at="{ item }">
            <v-chip text>{{ moment(item?.created_at).format("DD/MM/YYYY hh:mm A") }}</v-chip>
          </template>
        </data-table-component>
      </v-card-text>
    </v-card>
    <agregarProcesoCompra
        @cerrar="cerrarModal"
        :modal="modal"
        v-if="modal"/>
    <agregarProcesosCompra
        v-if="modalAgregarProceso"
        :idProceso="idProceso"
        @cerrar="modalAgregarProceso = false"
        :modal="modalAgregarProceso"/>
  </Fragment>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import {agregarProcesoCompra, agregarProcesosCompra} from "@/views/ComprasConjuntas/componentes/index";

export default {
  name: "listadoProcesosCompra conjunta",
  data: () => ({
    headers: [
      {
        text: "Nombre proceso",
        align: "start",
        sortable: false,
        value: "nombre_proceso",
      },
      {
        text: "Fecha creado",
        value: "created_at",
      },
      {
        text: "Tipo de proceso",
        value: "FormaContratacion.nombre",
      },
      {
        text: "Acciones",
        value: "acciones",
      }
    ],
    items: [],
    modal: false,
    modalAgregarProceso: false,
    idProceso: 0
  }),
  components: {
    DataTableComponent,
    agregarProcesoCompra,
    agregarProcesosCompra
  },
  methods: {
    async cargarProcesos() {
      const {data} = await this.services.Paac.obtenerProcesosConjuntos()
      this.items = data
    },
    async cerrarModal() {
      await this.cargarProcesos()
      this.modal = false
    }
  },
  async created() {
    await this.cargarProcesos()
  }
}
</script>
