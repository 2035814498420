<template>
  <div>
    <v-file-input
      outlined
      placeholder="Adjuntar archivo"
      label="Declaración jurada*"
      accept="application/pdf"
      prepend-icon="mdi-paperclip"
    />
    <v-btn color="secondary">Guardar</v-btn>
  </div>
</template>

<script>
export default {
  name: "DeclaracionJuradaComponent",
};
</script>

<style></style>
