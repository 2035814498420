<template>
  <v-row>
    <v-col cols="4">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header color="primary">
            <div class="d-flex align-center">
              <span class="bgMinsal--text px-3"> Registro Proveedores </span>
            </div>
            <template v-slot:actions>
              <v-icon color="bgMinsal"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row no-gutters>
              <v-col cols="12" class="mt-3">
                <v-btn block text @click="showConfig('terminoCondicion')">
                  Términos y condiciones</v-btn
                ></v-col
              >
              <v-col cols="12">
                <v-btn block text @click="showConfig('declaracionJurada')">
                  Declaración juradas</v-btn
                ></v-col
              >
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col cols="8">
      <TerminosCondiciones v-if="terminosCondiciones" />
      <DeclaracionJurada v-if="declaracionJurada" />
    </v-col>
  </v-row>
</template>

<script>
import TerminosCondiciones from "./components/TerminosCondicionesComponent.vue";
import DeclaracionJurada from "./components/DeclaracionJuradaComponent.vue";
export default {
  name: "ConfiguracionView",
  components: {
    TerminosCondiciones,
    DeclaracionJurada,
  },
  data: () => ({
    terminosCondiciones: false,
    declaracionJurada: false,
  }),
  methods: {
    showConfig(config) {
      if (config === "terminoCondicion") {
        this.terminosCondiciones = true;
        this.declaracionJurada = false;
      } else if (config === "declaracionJurada") {
        this.terminosCondiciones = false;
        this.declaracionJurada = true;
      }
    },
  },
};
</script>

<style></style>
