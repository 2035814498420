<template>
  <Fragment>
    <app-tab-component :items="opciones" v-if="opciones.length>0">
      <template v-slot:[`contenido`]="{item}">
        <component :is="item.component"></component>
      </template>
    </app-tab-component>
  </Fragment>
</template>
<script>
import {
  listadoProcesosCompra,
  listadoInstitucionesAdscritas,
  listadoFormasContratacion
} from "@/views/ComprasConjuntas/componentes/index";

export default {
  name: "MainComprasConjuntas",
  data: () => ({}),
  computed: {
    opciones() {
      return [
        {
          nombre: "Procesos",
          component: listadoProcesosCompra
        },
        {
          nombre: 'Instituciones adscritas',
          component: listadoInstitucionesAdscritas
        },
        {
          nombre: 'Formas de contratación disponibles',
          component: listadoFormasContratacion
        }
      ]
    }
  }
}
</script>