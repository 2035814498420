<template>
  <Fragment>
    <data-table-component :headers="headers" :items="modalidades" :inhabilitar_paginacion="true">
      <template v-slot:item.conjunta="{ item }">
        <v-chip :color="item?.conjunta ? 'light-blue':'gray'" text>{{item?.conjunta ? 'Activo' : 'Inactivo'}}</v-chip>
      </template>
      <template v-slot:item.acciones="{ item }">
        <v-btn v-if="item?.conjunta" @click="removerModalidad(item?.id)" color="secondary" class="secondary white--text" text>Remover</v-btn>
        <v-btn v-else @click="agregarModalidad(item?.id)" color="secondary" class="primary white--text" text>Agregar</v-btn>
      </template>
    </data-table-component>
  </Fragment>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  name: 'listadoFormasContratacion',
  components: {DataTableComponent},
  data: () => ({
    modalidades: [],
    headers: [
      {
        text: 'Nombre',
        align: 'start',
        sortable: false,
        value: 'nombre',
      },
      {
        text: 'Estado',
        value: 'conjunta',
      },
      {
        text: 'Acciones',
        value: 'acciones',
      }
    ]
  }),
  computed: {},
  methods: {
    async fetchModalidades() {
      const params = {
        compra_tradicional: true,
      }
      if (!this.idProceso) params.solo_migracion = false;
      const response = await this.services.Paac.getModalidades(params);
      if (response) {
        this.modalidades = response.data.forma_contratacion;
      }
    },
    async removerModalidad(id) {
      await this.services.Paac.removerModalidad(id)
      await this.fetchModalidades()
    },
    async agregarModalidad(id) {
      await this.services.Paac.agregarModalidad(id)
      await this.fetchModalidades()
    }
  },
  async mounted() {
    await this.fetchModalidades()
  }
}
</script>