<template>
  <Fragment>
    <v-card>
      <v-card-actions>
        <v-btn @click="modal=true" color="secondary">Agregar institución adscrita</v-btn>
      </v-card-actions>
      <v-card-text>
        <data-table-component :headers="headers" :items="items" :inhabilitar_paginacion="true">
          <template v-slot:item.acciones="{ item }">
            <v-btn class="secondary" @click="removerInstitucionAfiliada(item?.id)" color="primary" text>Remover</v-btn>
          </template>
        </data-table-component>
      </v-card-text>
    </v-card>
    <agregarInstitucionAdscrita :modal="modal" @close="cerrarModal"/>
  </Fragment>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import {agregarInstitucionAdscrita} from './index'

export default {
  name: "MainComprasConjuntas",
  data: () => ({
    headers: [
      {
        text: "Nombre institución",
        align: "start",
        sortable: false,
        value: "nombre",
      },
      {
        text: "Acciones",
        value: "acciones",
      }
    ],
    items: [],
    modal: false
  }),
  components: {
    DataTableComponent,
    agregarInstitucionAdscrita
  },
  methods: {
    async cerrarModal() {
      this.modal = false
      await this.getInstitucionesAfiliadas()
    },
    async getInstitucionesAfiliadas() {
      const {data} = await this.services.Instituciones.getCatalogoInstitucionesComprasConjuntas();
      this.items = data
    },
    async removerInstitucionAfiliada(id) {
      await this.services.Instituciones.removerInstitucionCompraConjunta(id)
      await this.getInstitucionesAfiliadas()
    }
  },
  async mounted() {
    await this.getInstitucionesAfiliadas()
  }
}
</script>