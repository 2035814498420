<template>
  <v-dialog v-model="modal" persistent>
    <v-card>
      <v-card-title class=" secondary white--text">
        <h2>Administrar proceso conjunto</h2>
      </v-card-title>
      <v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <template v-if="administrador_proceso">
                <v-alert type="success" outlined>
                  <v-row>
                    <v-col cols="12" md="8">
                      <strong>Administrador del proceso:</strong> {{ administrador_proceso?.Institucion?.nombre }}
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-btn @click="removerAdministrador" block class="secondary">Remover administrador</v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </template>
              <template v-else>
                <v-alert type="error" outlined>
                  <strong>Este proceso no tiene un administrador asignado</strong>
                </v-alert>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-autocomplete
                  :items="listado_instituciones"
                  v-model="institucion"
                  item-text="nombre"
                  return-object
                  item-value="id"
                  label="Seleccione la institución"
                  :search-input.sync="search"
                  outlined
                  @change="cargarProcesos"
              >
                <template v-slot:append-item>
                  <div v-intersect="endIntersect"></div>
                </template>
              </v-autocomplete>
              <template v-if="procesos.length > 0">
                <v-autocomplete
                    :items="procesos"
                    v-model="proceso"
                    item-text="nombre_proceso"
                    item-value="id"
                    label="Seleccione el proceso"
                    outlined
                />
                <v-btn rounded class="secondary white--text" @click="agregarProcesoHijo">Agregar proceso</v-btn>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <template v-if="procesos_hijos_length>0">
                <data-table-component
                    :headers="headers"
                    :items="procesos_hijos"
                    :tiene_paginacion="false"
                    :custom_item_per_page="procesos_hijos_length"
                >
                  <template v-slot:item.acciones="{ item }">
                    <v-btn
                        class="secondary mr-4"
                        @click="remover(item?.id)"
                        text>
                      Remover
                    </v-btn>
                    <v-btn
                        v-if="!administrador_proceso"
                        @click="agregarAdministrador(item?.id_institucion)"
                        class="secondary">
                      Promover a administrador
                    </v-btn>
                  </template>
                </data-table-component>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="terminar">Terminar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  name: "agregarProcesosHijos",
  data: () => ({
    listado_instituciones: [],
    nombre: '',
    institucion: null,
    proceso: null,
    procesoPadre: {
      procesos_hijos_length: 0,
      AdministradorProcesoCompra: null
    },
    procesos: [],
    headers: [
      {
        text: "Nombre proceso",
        align: "start",
        sortable: false,
        value: "nombre_proceso",
      },
      {
        text: "Institucion",
        value: "Institucion.nombre",
      },
      {
        text: "Acciones",
        value: "acciones",
      }
    ]
  }),
  components: {
    DataTableComponent
  },
  computed: {
    search: {
      get() {
        return this.nombre
      },
      async set(value) {
        if (this.nombre === value) return;
        this.nombre = value
        this.page = 1
        await this.cargarTextInput()
      }
    },
    procesos_hijos() {
      return this.procesoPadre?.procesos_hijos
    },
    procesos_hijos_length() {
      return this.procesoPadre?.procesos_hijos?.length
    },
    administrador_proceso() {
      return this.procesoPadre?.AdministradorProcesoCompra
    }
  },
  props: {
    modal: {
      type: Boolean,
      required: true
    },
    idProceso: {
      type: Number,
      required: true
    }
  },
  watch: {
    idProceso: {
      async handler() {
        await this.cargarProceso()
      },
      immediate: true
    }
  },
  methods: {
    async terminar() {
      this.institucion = null
      this.$emit('cerrar')
    },
    async mensajeCrud(mensaje) {
      this.proceso = null
      this.pushAppMessage({
        message: mensaje,
        type: 'success'
      })
      await this.cargarProceso()
      await this.cargarProcesos()
    },
    async remover(idProcesoHijo) {
      const {data, status} = await this.services.Paac.removerProcesoHijo(idProcesoHijo)
      if (status === 200) {
        await this.mensajeCrud(data)
      }
    },
    async agregarProcesoHijo() {
      if (!this.proceso) return this.pushAppMessage({
        message: 'Por favor seleccione un proceso',
        type: 'error'
      })
      const {data, status} = await this.services.Paac.agregarProcesoHijo(this.idProceso, this.proceso)
      if (status === 200) {
        await this.mensajeCrud(data)
      }
    },
    async cargarProcesos() {
      const {
        data
      } = await this.services.Paac.getProcesosCompra(this.institucion.id, this.procesoPadre.id_forma_contratacion);
      this.procesos = data
    },
    async cargarTextInput() {
      this.page = 1
      const {data, status} = await this.cargarInstituciones();
      if (status === 200 && data.length > 0) {
        this.listado_instituciones = data
      }
    },
    async cargarProceso() {
      this.procesoPadre = {
        procesos_hijos_length: 0
      }
      if (Number.isInteger(this.idProceso) && this.idProceso > 0) {
        const {data} = await this.services.Paac.getProcesoCompra(this.idProceso)
        this.procesoPadre = data
      }
    },
    async cargarInstituciones() {
      const {data, status} = await this.services.Instituciones.getCatalogoInstitucionesComprasConjuntasAdscritas({
        page: this.page,
        nombre: this.nombre
      });
      return {
        data,
        status
      }
    },
    async cargarScroll() {
      this.page++
      const {data, status} = await this.cargarInstituciones();
      if (status === 200 && data.length > 0) {
        this.listado_instituciones = [...this.listado_instituciones, ...data]
      } else {
        this.page--
      }
    },
    async endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        await this.cargarScroll();
      }
    },
    async agregarAdministrador(id_institucion) {
      await this.services.Paac.agregarAdministradorProcesoConjunto(this.idProceso, id_institucion)
      await this.cargarProceso()
    },
    async removerAdministrador() {
      await this.services.Paac.removerAdministradorProcesoConjunto(this.idProceso)
      await this.cargarProceso()
    }
  }
}
</script>
