<template>
  <Fragment>
    <v-dialog v-model="modal" persistent>
      <v-card class="pb-0 mb-0">
        <v-card-title class="white--text secondary">
          <h2>Agregar proceso de compra conjunto</h2>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4" no-gutters>
            <v-col cols="12" sm="6">
              <v-textarea
                  v-model="form.nombre_proceso"
                  outlined
                  label="Nombre del proceso *"
                  @blur="$v.form.nombre_proceso.$touch()"
                  @input="$v.form.nombre_proceso.$touch()"
                  :error-messages="nombreProcesoError"
              >
              </v-textarea>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                  v-model="form.id_modalidad"
                  :items="modalidades"
                  item-text="nombre"
                  item-value="id"
                  label="Modalidad de compra *"
                  outlined
                  :readonly="!!idProceso"
                  @blur="$v.form.id_modalidad.$touch()"
                  @input="$v.form.id_modalidad.$touch()"
                  :error-messages="modalidadError"
              ></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-menu
                  max-width="290px"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="menuInicio"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      @click:clear="picker = null"
                      :value="fechaFormatted(picker)"
                      append-icon="mdi-calendar"
                      clearable
                      label="Inicio del proceso *"
                      :error-messages="fechaInicioError"
                      @blur="$v.picker.$touch()"
                      outlined
                      placeholder="Seleccione una fecha"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="picker"
                    type="month"
                    locale="es-Es"
                    header-color="#404a7a"
                    style="margin-right: 40px; margin-top: 15px"
                    :min="moment().format('YYYY-MM-DD')"
                />
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                  max-width="290px"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                  v-model="menuFin"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      @click:clear="picker2 = null"
                      :value="fechaFormatted(picker2)"
                      append-icon="mdi-calendar"
                      clearable
                      :error-messages="fechaFinError"
                      @blur="$v.picker2.$touch()"
                      label="Finalización del proceso *"
                      outlined
                      placeholder="Seleccione una fecha"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    :min="picker"
                    :disabled="picker === ''"
                    v-model="picker2"
                    type="month"
                    locale="es-Es"
                    style="margin-top: 15px; color-text: white"
                    header-color="#404a7a"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col md="12" cols="12">
              <v-autocomplete
                  :items="listadoanios"
                  label="Seleccione el año fiscal"
                  v-model="form.anio"
                  item-text="nombre"
                  item-value="id"
                  outlined
                  :error-messages="anioFiscalError"
                  @blur="$v.form.anio.$touch()"
              >

              </v-autocomplete>
            </v-col>
          </v-row>
          <v-divider class="my-6"></v-divider>
        </v-card-text>
        <v-card-actions class="pt-0 mt-0">
          <v-btn class="secondary" @click="guardarProceso">Guardar</v-btn>
          <v-btn class="primary" @click="$emit('cerrar')">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Fragment>
</template>
<script>
import {Fragment} from "vue-fragment";
import moment from "moment/moment";
import {decimal, helpers, required} from "vuelidate/lib/validators";

const letter = helpers.regex("letter", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 -]*$/); // [A-Za-zñÑáéíóúÁÉÍÓÚ' ]
const priceDecimals = helpers.regex(
    "priceDecimals",
    /^[0-9]+([.][0-9]{1,2})?$/
);
export default {
  name: "AgregarProcesoCompra",
  components: {
    Fragment
  },
  data: () => ({
    form: {
      nombre_proceso: '',
      id_modalidad: null,
      anio: null,
    },
    menuFin: false,
    menuInicio: false,
    listadoanios: [],
    modalidades: [],
    picker: moment().format("YYYY-MM"),
    picker2: null,
  }),
  validations: {
    form: {
      nombre_proceso: {required, letter},
      id_modalidad: {required},
      anio: {required},
    },
    picker: {required},
    picker2: {required},
  },
  computed: {
    nombreProcesoError() {
      const errors = [];
      if (!this.$v.form.nombre_proceso.$dirty) return errors;
      !this.$v.form.nombre_proceso.required &&
      errors.push("El nombre del proceso es requerido");
      !this.$v.form.nombre_proceso.letter &&
      errors.push(
          "El nombre del proceso no puede contener caracteres especiales"
      );
      return errors;
    },
    anioFiscalError() {
      const errors = [];
      if (!this.$v.form.anio.$dirty) return errors;
      !this.$v.form.anio.required &&
      errors.push("El año fiscal es requerido");
      return errors;
    },
    modalidadError() {
      const errors = [];
      if (!this.$v.form.id_modalidad.$dirty) return errors;
      !this.$v.form.id_modalidad.required &&
      errors.push("La modalidad de compra es requerida");
      return errors;
    },
    fechaInicioError() {
      const errors = [];
      if (!this.$v.picker.$dirty) return errors;
      !this.$v.picker.required && errors.push("Debe de seleccionar una fecha");
      return errors;
    },
    fechaFinError() {
      const errors = [];
      if (!this.$v.picker2.$dirty) return errors;
      !this.$v.picker2.required && errors.push("Debe de seleccionar una fecha");
      return errors;
    },
  },
  props: {
    idProceso: {
      type: Number,
      required: false,
      default: null
    },
    modal: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    fechaFormatted(fecha) {
      if (fecha !== null) {
        return moment(fecha).format("MMMM YYYY");
      } else {
        return "";
      }
    },
    async fetchModalidades() {
      const params = {
        compra_tradicional: true,
        conjunta: true,
      }
      if (!this.idProceso) params.solo_migracion = false;
      const response = await this.services.Paac.getModalidades(params);
      if (response) {
        this.modalidades = response.data.forma_contratacion;
      }
    },
    async obtenerAniosFiscales() {
      const {data} = await this.services.PacServices.listadoAniosFiscales()
      this.listadoanios = data
    },
    async guardarProceso() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.pushAppMessage({
          type: "error",
          message: "Por favor, revise los campos marcados en rojo.",
        });
        return;
      }
      if (this.picker === null || this.picker2 === null) {
        this.pushAppMessage({
          show: true,
          type: "error",
          message: "Debe establecer el inicio y fin del proceso",
        });
        return;
      }
      const params = {
        id_modalidad_compra: this.form.id_modalidad,
        nombre_proceso: this.form.nombre_proceso,
        fecha_inicio: this.moment(this.picker)
            .startOf("month")
            .format("DD-MM-YYYY"),
        fecha_fin: this.moment(this.picker2)
            .endOf("month")
            .format("DD-MM-YYYY"),
        anio_fiscal: this.form.anio,
      };
      await this.services.Paac.crearProcesoConjunto(params)
      this.defaultForm()
      this.$emit('cerrar')
    },
    defaultForm() {
      this.form = {
        nombre_proceso: '',
        id_modalidad: null,
        anio: null,
      }
      this.menuFin = false
      this.menuInicio = false
      this.listadoanios = []
      this.modalidades = []
      this.picker = moment().format("YYYY-MM")
      this.picker2 = null
      this.$v.form.$reset()
      this.$v.picker.$reset()
      this.$v.picker2.$reset()
    }
  },
  async created() {
    await this.fetchModalidades()
    await this.obtenerAniosFiscales()
  }

}

</script>